import React from 'react';
import {
  BrowserRouter
} from "react-router-dom";

import Home from './Home';
import About from './About';
import Skills from './Skills';
import Portfolio from './Portfolio';
import Contact from './Contact';
import Navbar from './Navbar';
import Footer from './Footer';

const Navigation = () => {
  return (
    <BrowserRouter>
      <Navbar />
      {/*<Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>*/}
      <Home />
      <About />
      <Skills />
      <Portfolio />
      <Contact />
      <Footer/>
    </BrowserRouter>
  );
}

export default Navigation;