import './About.css';
import { HashLink } from 'react-router-hash-link';

function About() {
    return (
        <section id='about' className='sec-about'>
            <div>
                <h2 className='container'>hello,</h2>
                <p className=' container'>My name is <span className='my-name'>nadia nouri</span> ,  I'm basically from Morocco and living in Stockholm. I have worked as a
                    French teacher. I studied Tourisme: Management & communication in Morocco. I'm into web design and web development. My passion for web development started as a curiosity about how websites work. In 2020 I decided to switch my
                    career from Tourisme to Programming. I joined IT for women as a student in January 2022. I'm passionate about
                    learning new skills and I have completed a number of courses. I love being creative and I enjoy
                    working as a team which gives me more opportunities to grow up.
                </p>

            </div>
            <div className='my-info container'>
                <h5>birthday : </h5>
                <p> 16 September</p>
                <h5>phone : </h5>
                <p>+46728644745</p>
                <h5>email : </h5>
                <p>nadianour169@gmail.com</p>
                <h5>language : </h5>
                <p>Arabic, English</p>
                <h5>freelance : </h5>
                <p>Available</p>
            </div>
            <div className='container about-btn'>
                <HashLink to="#contact">
                    <button className='btn'>Contact me</button>
                </HashLink>
            </div>
        </section>


    );
}

export default About;