
import  './Contact.css';
import Form from './Form';

function Contact() {

        return (
            <section className="sec-contact container" id='contact'>
            <Form />
            </section>
        ); 
}

export default Contact;