import { useState } from 'react';
import { NavHashLink} from 'react-router-hash-link';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';


function Navbar() {
    //const { hash } = useLocation(); 
    const location = useLocation();
    const hash = location.hash;

    // const isActive = (currentHash) => hash === currentHash;
    function isActive(currentHash) {
        if(hash === currentHash)
            return true;
        else  
            return false;   
    }

    const [isMobile, setIsMobile] = useState(false);
    return (
        <header>
            <nav>
                <h3 className='logo'>nadia nouri<span>.</span></h3>
                <ul className={isMobile?'nav-links-mobile':'nav-links'}
                onClick={() => setIsMobile(false)}
                >
                    <li><NavHashLink  style={ isActive("#home")? {color: "#81b29a"}: {}}  to="#home">Home</NavHashLink></li>
                    <li><NavHashLink  style={ isActive("#about")? {color: "#81b29a"}: {}}  to="#about" >about me</NavHashLink></li>
                    <li><NavHashLink  style={ isActive("#skills")? {color: "#81b29a"}: {}}  to="#skills">skills</NavHashLink></li>
                    <li><NavHashLink  style={ isActive("#portfolio")? {color: "#81b29a"}: {}}  to="#portfolio">portfolio</NavHashLink></li>
                    <li><NavHashLink  style={ isActive("#contact")? {color: "#81b29a"}: {}}  to="#contact">contact me</NavHashLink></li>
                    <li><Link to="resume.pdf" target="_blank" className='top-btn' download>Download CV</Link></li>
                </ul>
                <button className='mobile-menu-icon'
                onClick={() => setIsMobile(!isMobile)}
                >
                    {isMobile ? (<i className='fas fa-times '></i>
                    ) : (
                        <i class="fa-solid fa-bars"></i>
                    )
                    }
                </button>
            </nav>
        </header>
    );
}

export default Navbar;