import './Home.css';
import { HashLink } from 'react-router-hash-link';


function Home() {
    return (
        <section id='home'>
            <div className='sec-home'>
                <h3 className='greeting-word'>Hi there..</h3>
                <h4>I'm <span className='my-name'>Nadia Nouri</span></h4>
                <h4>I'm a <span className='my-name'>Front-end developer</span></h4>
                <div className='container about-btn'>
                    <HashLink to="#about">
                        <button className='btn'>About me</button>
                    </HashLink>
                </div>                
            </div>
        </section>


    );
}

export default Home;