import { Link } from 'react-router-dom';
import './Portfolio.css';

function Portfolio() {
    return (
        <section id='portfolio' className='sec-portfolio container'>
            <div>
                <h2 className='title'>my works</h2>
                <p className='portfolio-para' >Here you can see some examples of my work during my course at Sundsgården Folkhögskola with a short description of each project. Feel free to check my GitHub if you want to see the code for the project.</p>
            </div>
            <div className='card-container'>
                <div className='card portfolio-card'>
                    <Link to="Sass_project/index.html" target="_blank" >
                        <img class="card-img-top portfolio-imgs" src={require('../images/img-genesis.png')} alt="productly img" />
                    </Link>
                    <div className='card-body'>
                        <h5 className='card-title'>GENESIS portfolio</h5>
                        <p className="card-text container card-para"> The project was created based on a design in Figma. This portfolio is made up of three pages and they are linked to each other through navigation.This project is created and styled using HTML, CSS, and Sass.</p>
                    </div>
                </div>
                <div className='card portfolio-card'>
                    <Link to="Bhromaon/index.html" target="_blank" >
                        <img class="card-img-top portfolio-imgs" src={require('../images/img-bhromaon.png')} alt="productly img" />
                    </Link>
                    <div className='card-body'>
                        <h5 className='card-title'>BHROMAON Travel:</h5>
                        <p className="card-text container card-para">In this project, I challenged myself to create these three fully responsive pages within 12 hours by using HTML, CSS and Sass. This challenge taught me how do I build a project under stress. It was a good experience for me because I have not expected that I can make a large and responsive landing page within a narrow timeline.</p>
                    </div>
                </div>
                <div className='card portfolio-card'>
                    <Link to="Cosmetics/index.html" target="_blank" >
                        <img class="card-img-top portfolio-imgs" src={require('../images/img-cosmetics.png')} alt="productly img" />
                    </Link>
                    <div className='card-body'>
                        <h5 className='card-title'>JAMALi:</h5>
                        <p className="card-text container card-para">In this project, we worked as a team. The project was about cosmetics and it was created from scratch based on a design in Figma, it also links three pages. In This project, my task was to build a sketch in Figma and create the landing page, header, and footer by using HTML, CSS, and Sass. The project also is fully responsive.</p>
                    </div>
                </div>
                <div className='card portfolio-card'>
                    <Link to="Productly/index.html" target="_blank" >
                        <img class="card-img-top portfolio-imgs" src={require('../images/img-productly.png')} alt="productly img" />
                    </Link>
                    <div className='card-body'>
                        <h5 className='card-title'>PRODUCTLY:</h5>
                        <p className="card-text container card-para">In this assignment, we worked on how to place elements on our page with the help of CSS. The project was designed on Figma and we created it by using HTML and CSS.
                            This project is among the first assignments I worked on, and I'm happy with how the result turned out.</p>
                    </div>
                </div>
            </div>

        </section>


    );
}
export default Portfolio;

