import './Footer.css';

const Footer = () => {
    return (
        <footer className='social'>
            <p className='copyright-para'>Ⓒ 2022 created by nadia nouri</p>
            <a href="https://github.com/nadianouri" target="_blank" rel="noopener noreferrer"><i className='bx bxl-github'></i></a>
            <a href="http://"><i className='bx bxl-twitter'></i></a>
            <a href="http://linkedin.com/in/nadia-nouri-905a42241/" target="_blank" rel="noopener noreferrer"><i className='bx bxl-linkedin'></i></a>

        </footer>

    );
};

export default Footer;
