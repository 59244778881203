import { useState } from "react";
import  './Form.css';
import emailjs from '@emailjs/browser';

function Form() {
        const [fullName, setFullName]  = useState("");
        const [email, setEmail] =useState("");
        const [message, setMessage]  = useState("");
        const [showTextMessage, setshowTextMessage] = useState("none");
        const [hideForm, setHideForm] = useState ("block");
        const [errorFullName,setErrorFullName] = useState("");
        const [errorEmail,setErrorEmail] = useState("");
        const [errorMessage,setErrorMessage] = useState("");
        const sendEmail = (templateParams) => {
             // Send Email to me
            // prepare data
            // Send Email 
            emailjs.send('service_78b91mu', 'template_i6pt7q9',templateParams , 'vcVK9vQaiPhNrBmTe');
        }


        const  handleFromSubmit = (e) => {
            e.preventDefault();
            //email validation
            let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if  (emailValid){
                setErrorEmail("");
            } else {
                setErrorEmail("The Email is invalid");
            };
            //username validation
            let fullNameValid = fullName.length > 0 ;
            if  (fullNameValid){
                setErrorFullName("");
            }else {
                setErrorFullName("The Name is invalid");
            }
            // message validation 
            let messageValid = ( message.length > 0 && message.length < 500 );
            if (messageValid){
                setErrorMessage ('');
            }else{
                if(message.length > 500){
                    setErrorMessage('Your message is too long');
                }else{
                    setErrorMessage('Please enter your message');
                }   
            };
            // all fields is required 
            //email validation
            if(emailValid && fullNameValid && messageValid) {

                let templateParams = {
                    name: fullName,
                    email: email,
                    message: message
                };
                sendEmail(templateParams);
                
                  // clear fields
                setFullName("");
                setEmail("");
                setMessage("");
                // hide form and show thanks message
                setshowTextMessage("block");
                setHideForm("none");
            }
        };
        const hideTextMessage = (e) => {
            setshowTextMessage("none");
            setHideForm("block");
        }
        return ( 
                <>
                <div style={{ display: showTextMessage}}>
                    <h3 className='title' id="message_text">Thanks message</h3>
                    <p> Thank you for your message. I will contact you later on. </p>
                    <button className="btn submit-btn" onClick={(e) => hideTextMessage(e)}>Close</button> 
                </div>
                <div style={ (errorFullName === "" && errorEmail === ""&& errorMessage === "")? {display: "None"}:{} }>
                </div>
                <div style={{ display: hideForm}}  className="form">
                    <form className="container">
                        <label htmlFor="name"></label>
                        <input type="text" name="name" id="name" placeholder="Your Name" onChange={(e) => setFullName(e.target.value)} value={fullName}/> 
                        <p className="error-message" style={ (errorFullName === "" )? {display: "None" , color:'Red',fontSize:'16px' ,}:{} }>  {errorFullName}</p>
                        <label for="email"></label>
                        <input type="email" name="email" id="email" placeholder="Your Email" onChange={(e)=> setEmail(e.target.value)} value={email}></input>
                        <p className="error-message" style={ (errorEmail === "" )? {display: "None"}:{} }>{errorEmail}</p>
                        <textarea name="message" placeholder="Enter your message" onChange={(e) => setMessage(e.target.value)} value={message}/>
                        <p className="error-message"style={ (errorMessage === "" )? {display: "None"}:{} }>  {errorMessage}</p>
                        <button className="btn submit-btn" onClick={(e) => handleFromSubmit(e)}>Submit</button> 
                    </form>
                    

                </div>   
            </>
        ); 
}
export default Form;

