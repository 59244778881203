import './Skills.css';

function Skills() {
    return (
        <section className ='container' id='skills'>
            <h2 className='title'>my abilities..</h2>
            <div className='sec-skills' >
                <i class='bx bx-badge-check'></i>
                <h3 className='skills-name' >HTML</h3>
                <span className='skills-level' >advanced</span>
                <i class='bx bx-badge-check'></i>
                <h3 className='skills-name' >CSS</h3>
                <span className='skills-level' >advanced</span>
                <i class='bx bx-badge-check'></i>
                <h3 className='skills-name' >JavaScript</h3>
                <span className='skills-level' >intermediate</span>
                <i class='bx bx-badge-check'></i>
                <h3 className='skills-name' >Bootstrap</h3>
                <span className='skills-level' >advanced</span>
                <i class='bx bx-badge-check'></i>
                <h3 className='skills-name' >Sass</h3>
                <span className='skills-level' >advanced</span>
                <i class='bx bx-badge-check'></i>
                <h3 className='skills-name' >Git</h3>
                <span className='skills-level' >intermediate</span>
                <i class='bx bx-badge-check'></i>
                <h3 className='skills-name' >React</h3>
                <span className='skills-level' >intermediate</span>
                <i class='bx bx-badge-check'></i>
                <h3 className='skills-name' >Figma</h3>
                <span className='skills-level' >advanced</span>
                <i class='bx bx-badge-check'></i>
                <h3 className='skills-name' >AdobeXD</h3>
                <span className='skills-level' >advanced</span>
            </div>




        </section>


    );
}

export default Skills;